import { useSnackbar } from 'notistack'
import { useState } from 'react'
import NextLink from 'next/link'
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem, CircularProgress, Button } from '@mui/material'
import { PATH_ACCOUNT, PATH_APP_SERVICES } from '@/routes/paths'
import useAuth from '@/hooks/useAuth'
import useApp from '@/hooks/useApp'
import { ModuleEnum } from '@/appConfig'
import useIsMountedRef from '@/hooks/useIsMountedRef'
import MyAvatar from '@/components/MyAvatar'
import MenuPopover from '@/components/MenuPopover'
import { IconButtonAnimate } from '@/components/animate'

const MENU_OPTIONS: {
  label: string
  linkTo: string
}[] = [
    {
      label: 'Home',
      linkTo: '/',
    },
    {
      label: 'App Store',
      linkTo: PATH_APP_SERVICES.root
    },
    {
      label: 'Billing',
      linkTo: PATH_ACCOUNT.service,
    },
    // {
    //   label: 'Settings',
    //   linkTo: PATH_DASHBOARD.user.account,
    // },
  ]

// ----------------------------------------------------------------------

export default function AccountPopover({ withName }: { withName?: boolean }) {
  const { login, user, logout, isLoading } = useAuth()
  const { modules: appModules } = useApp()
  const isMountedRef = useIsMountedRef()

  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState<HTMLElement | null>(null)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleLogout = async () => {
    try {
      await logout()

      if (isMountedRef.current) {
        handleClose()
      }
    } catch (error) {
      console.error(error)
      enqueueSnackbar('Unable to logout!', { variant: 'error' })
    }
  }

  const authEnabled = appModules.some((appModule) => appModule === ModuleEnum.Auth)

  return isLoading ? (
    <CircularProgress size={20} />
  ) : user ? (
    <>
      <Stack direction="row" alignItems="center" spacing={1.5}>
        <IconButtonAnimate
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
              },
            }),
          }}
        >
          <MyAvatar />
        </IconButtonAnimate>
        {withName && <Typography variant="body2">{user?.name}</Typography>}
      </Stack>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <NextLink key={option.label} href={option.linkTo} passHref>
              <MenuItem key={option.label} onClick={handleClose}>
                {option.label}
              </MenuItem>
            </NextLink>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  ) : authEnabled ? (
    <Button
      variant="contained"
      onClick={() =>
        login({
          returnTo: PATH_APP_SERVICES.root,
        })
      }
    >
      Login
    </Button>
  ) : (
    <></>
  )
}
