import uniqBy from 'lodash/uniqBy'
import { useState, useEffect, ReactNode } from 'react'
// next
import NextLink from 'next/link'
import { useRouter } from 'next/router'
// @mui
import { alpha, styled } from '@mui/material/styles'
import {
  Box,
  Stack,
  List,
  Link,
  Drawer,
  Collapse,
  LinkProps,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItemButtonProps,
} from '@mui/material'
// config
import { NAVBAR } from '../../config'
// components
import Logo from '@/components/Logo'
import Iconify from '@/components/Iconify'
import Scrollbar from '@/components/Scrollbar'
import { IconButtonAnimate } from '@/components/animate'
import { NavSectionVertical } from '@/components/nav-section'
import { MenuProps, MenuItemProps } from './type'
import LanguagePopover from '@/layouts/dashboard/header/LanguagePopover'
import { ICON_SIZE } from './MenuConfig'
import SocialMediaLinksButton from '@/components/SocialMediaLinksButton'
import useApp from '@/hooks/useApp'
import AccountPopover from '../shared/AccountPopover'

type StyleProps = LinkProps & ListItemButtonProps

interface ListItemStyleProps extends StyleProps {
  component?: ReactNode
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: NAVBAR.DASHBOARD_ITEM_ROOT_HEIGHT,
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
}))

// ----------------------------------------------------------------------

export default function MenuMobile({ isOffset, isHome, navConfig, isLocaleEnabled }: MenuProps) {
  const { pathname } = useRouter()
  const {
    socialMediaLinksCollection,
    mobileDrawerDisplayPagesCollection,
    renameCallToActionLinksDisplayText,
  } = useApp()

  const [open, setOpen] = useState(false)

  const [drawerOpen, setDrawerOpen] = useState(false)

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  const handleOpen = () => {
    setOpen(!open)
  }

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const moreItems: MenuItemProps[] = (mobileDrawerDisplayPagesCollection?.items ?? []).map(
    (item) => ({
      path: item.slug,
      title: item.displayName ?? item.slug,
      icon: item.iconifyIconNameForMobileMenu ?? 'material-symbols:arrow-forward',
    })
  )

  const navConfigItems = uniqBy([...navConfig, ...moreItems], (item) => item.path)

  return (
    <>
      <IconButtonAnimate
        onClick={handleDrawerOpen}
        sx={{
          ml: 1,
          mr: -0.5,
          ...(isHome && { color: 'common.white' }),
          ...(isOffset && { color: 'text.primary' }),
        }}
      >
        <Iconify icon={'eva:menu-2-fill'} />
      </IconButtonAnimate>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260 } }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2, my: 3, justifyContent: 'start', maxWidth: '150px' }} />

          <List disablePadding>
            {navConfigItems.map((link) => (
              <MenuMobileItem key={link.title} item={link} isOpen={open} onOpen={handleOpen} />
            ))}
          </List>

          <Stack direction="column" alignItems="start" spacing={3} sx={{ mt: 3, ml: 2 }}>
            {isLocaleEnabled && <LanguagePopover showLanguageText fullWidth />}

            <AccountPopover withName />
            <SocialMediaLinksButton
              buttonTitle={renameCallToActionLinksDisplayText}
              linksCollection={socialMediaLinksCollection}
              onClose={handleDrawerClose}
            />
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  )
}

// ----------------------------------------------------------------------

type MenuMobileItemProps = {
  item: MenuItemProps
  isOpen: boolean
  onOpen: VoidFunction
}

function MenuMobileItem({ item, isOpen, onOpen }: MenuMobileItemProps) {
  const { pathname } = useRouter()
  const { title, path, icon, children } = item

  const isActive = pathname === path
  const iconElement = <Iconify icon={icon || ''} {...ICON_SIZE} />
  if (children) {
    return (
      <>
        <ListItemStyle onClick={onOpen}>
          <ListItemIcon>{iconElement}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
          <Iconify
            icon={isOpen ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <NavSectionVertical
              navConfig={children}
              sx={{
                '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                  height: 200,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  bgcolor: 'background.neutral',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage:
                    'url(https://minimal-assets-api.vercel.app/assets/illustrations/illustration_dashboard.png)',
                  '& > *:not(.MuiTouchRipple-root)': { display: 'none' },
                },
              }}
            />
          </Box>
        </Collapse>
      </>
    )
  }

  if (title === 'Documentation') {
    return (
      <ListItemStyle href={path} target="_blank" rel="noopener" component={Link}>
        <ListItemIcon>{iconElement}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle>
    )
  }

  return (
    <NextLink href={path} passHref>
      <ListItemStyle
        sx={{
          ...(isActive && {
            color: 'primary.main',
            fontWeight: 'fontWeightMedium',
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
          }),
        }}
      >
        <ListItemIcon>{iconElement}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle>
    </NextLink>
  )
}
