import { forwardRef } from 'react'
import NextLink from 'next/link'
import { Box, BoxProps } from '@mui/material'
import styled from 'styled-components'
import useApp from '@/hooks/useApp'
import { AppIdEnum } from '@/appConfig'
import layout from '@/theme/layout'

const Img = styled.img`
  height: 100%;
  object-fit: contain;
  @media (max-width: 768px) {
    height: 100%;
  }
`

const ImgContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${layout.appBar.desktop - layout.elementGap.desktop}px;
  @media (max-width: 768px) {
    height: ${layout.appBar.mobile - layout.elementGap.mobile}px;
  }
`

interface Props extends BoxProps {
  isOffset?: boolean
  isHome?: boolean
  isMint?: boolean
  disabledLink?: boolean
}

const Logo = forwardRef<any, Props>(
  ({ isHome, isMint, isOffset, disabledLink = false, sx }, ref) => {
    const { id: appId, logo: appLogo } = useApp()

    const getLogoSrc = (appId: AppIdEnum) => {
      switch (appId) {
        // custom logo because of white and black
        case AppIdEnum.agafgoon:
          return (isHome && !isOffset) || isMint ? '/logo/agaf-white.png' : appLogo
        default:
          return appLogo
      }
    }

    const logo = (
      <ImgContainer ref={ref} sx={{ cursor: 'pointer', ...sx }}>
        <Img src={getLogoSrc(appId) ?? undefined} />
      </ImgContainer>
    )

    if (disabledLink) {
      return <>{logo}</>
    }

    return <NextLink href="/">{logo}</NextLink>
  }
)

export default Logo
