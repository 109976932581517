// @mui
import {
  Link,
  Stack,
  Button,
  Menu,
  MenuItem,
  Typography,
  ButtonProps,
  IconButtonProps,
} from '@mui/material'
import useLocales from '@/hooks/useLocales'
import { Link as ScrollLink } from 'react-scroll'
import Iconify from './Iconify'
import { useState } from 'react'
import styled from 'styled-components'
import palette from '@/theme/palette'
import { ISocialMediaLinkFields } from '@/generated/contentful.types.node'
import { RootSettingsFragment } from '@/generated/graphql'

const StyledMenu = styled(Menu)`
  .MuiPaper-root {
    background: linear-gradient(${palette.light.grey[900]}, ${palette.light.grey[800]});
  }
`

type IProps = IconButtonProps & ButtonProps

interface Props extends IProps {
  linksCollection?: RootSettingsFragment['socialMediaLinksCollection']
  buttonTitle?: string | null
  onClose?: () => void
}

type SocialLinkType = ISocialMediaLinkFields['type']

// icon source demo: https://icon-sets.iconify.design/
const socialMediaIcons: { type: SocialLinkType; icon: string }[] = [
  {
    type: 'web',
    icon: 'eva:globe-outline',
  },
  {
    type: 'email',
    icon: 'eva:email-outline',
  },
  {
    type: 'facebook',
    icon: 'eva:facebook-outline',
  },
  {
    type: 'instagram',
    icon: 'ant-design:instagram-outlined',
  },
  {
    type: 'discord',
    icon: 'mingcute:discord-line',
  },
  {
    type: 'twitter',
    icon: 'eva:twitter-outline',
  },
  {
    type: 'line',
    icon: 'cib:line',
  },
  {
    type: 'youtube',
    icon: 'cib:youtube',
  },
  {
    type: 'phone',
    icon: 'bxs:phone-outgoing',
  },
]

export default function SocialMediaLinksButton({
  linksCollection,
  buttonTitle,
  onClose,
  ...buttonProps
}: Props) {
  const { translate } = useLocales()
  const [isOpen, setOpen] = useState<null | HTMLElement>(null)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
    onClose?.()
  }

  const socialMediaLinks =
    linksCollection?.items.filter((link) =>
      socialMediaIcons.some((social) => social.type === link?.type)
    ) ?? []

  return socialMediaLinks.length ? (
    <>
      <Button variant="contained" onClick={handleOpen} {...buttonProps}>
        {buttonTitle || translate('common.contactUs')}
      </Button>
      <StyledMenu
        keepMounted
        id="simple-menu"
        anchorEl={isOpen}
        onClose={handleClose}
        open={Boolean(isOpen)}
        sx={{ mt: 1 }}
      >
        {socialMediaLinks
          .map((social) => ({
            ...social,
            type: social?.type ?? 'web',
          }))
          .map((social) => {
            const socialMediaIcon = socialMediaIcons.find((icon) => icon.type === social.type)
            const isExternalProps = social.isExternal ? { target: '_blank', rel: 'noopener' } : {}
            const overwriteDisplayIconWithIconifyIcon = social.overwriteDisplayIconWithIconifyIcon

            const href =
              social.type === 'email'
                ? `mailto: ${social.link}`
                : social.type === 'phone'
                ? `tel:${social.link}`
                : social.link ?? undefined

            return (
              <MenuItem key={social.title} onClick={handleClose}>
                <Link href={href} variant="body1" {...isExternalProps}>
                  <Stack direction="row" spacing={3} alignItems="center">
                    {socialMediaIcon && (
                      <Iconify
                        icon={overwriteDisplayIconWithIconifyIcon ?? socialMediaIcon.icon}
                        sx={{
                          width: 30,
                          height: 30,
                        }}
                      />
                    )}
                    <Typography>{social.title}</Typography>
                  </Stack>
                </Link>
              </MenuItem>
            )
          })}
      </StyledMenu>
    </>
  ) : (
    <ScrollLink to="contact" spy smooth offset={-80}>
      <Button variant="contained" {...buttonProps} onClick={handleClose}>
        {buttonTitle || translate('common.contactUs')}
      </Button>
    </ScrollLink>
  )
}
