import { useState } from 'react'
import { MenuItem, Stack, Typography } from '@mui/material'
import useLocales from '../../../hooks/useLocales'
import useApp from '../../../hooks/useApp'
import MenuPopover from '../../../components/MenuPopover'
import { IconButtonAnimate } from '../../../components/animate'
import { getAppLocaleConfig } from '@/appConfig'
import styled from 'styled-components'

const StyledImage = styled.img`
  @media (max-width: 768px) {
    width: 28px;
  }
`

const Wrapper = styled.div<{ fullWidth?: boolean }>`
  ${(props) => props.fullWidth && `width: 100%;`}
  display: flex;
  flex-direction: row;
  align-items: center;
`

interface LanguagePopoverProps {
  showLanguageText?: boolean
  fullWidth?: boolean
}

export default function LanguagePopover(props: LanguagePopoverProps) {
  const { allLang, currentLang, onChangeLang } = useLocales()
  const { id: appId } = useApp()
  const [open, setOpen] = useState<HTMLElement | null>(null)
  const { locales: appLocales } = getAppLocaleConfig(appId)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  return (
    <>
      <Wrapper fullWidth={props.fullWidth} onClick={handleOpen}>
        <IconButtonAnimate
          sx={{
            width: 40,
            height: 40,
            ...(open && { bgcolor: 'action.selected' }),
          }}
        >
          <StyledImage src={currentLang.icon} alt={currentLang.label} />
        </IconButtonAnimate>
        {props.showLanguageText && <Typography variant="body1">{currentLang.label}</Typography>}
      </Wrapper>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {allLang
            .filter(({ value }) => appLocales.includes(value))
            .map((option) => (
              <MenuItem
                key={option.value}
                selected={option.value === currentLang.value}
                onClick={() => {
                  onChangeLang(option.value)
                  handleClose()
                }}
              >
                <StyledImage alt={option.label} src={option.icon} />
                <Typography style={{ marginLeft: 10 }}>{option.label}</Typography>
              </MenuItem>
            ))}
        </Stack>
      </MenuPopover>
    </>
  )
}
