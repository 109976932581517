import { PATH_PAGE } from '../../routes/paths'
import { ModuleEnum } from '@/appConfig'

export const ICON_SIZE = {
  width: 22,
  height: 22,
}

const menuConfig = [
  {
    title: 'Home',
    icon: 'eva:home-fill',
    path: '/',
  },
  {
    title: 'Mint',
    icon: 'ic:round-grain',
    path: PATH_PAGE.mint,
    isTranslateDisabled: true,
    enabledModule: ModuleEnum.Mint,
  },
  {
    title: 'Blog',
    icon: 'eva:book-open-fill',
    path: PATH_PAGE.blog,
    enabledModule: ModuleEnum.Blog,
  },
  {
    title: 'Product',
    icon: 'mingcute:shopping-bag-1-fill',
    path: PATH_PAGE.products,
    enabledModule: ModuleEnum.Product,
  },
  {
    title: 'Glossary',
    icon: 'tabler:vocabulary',
    path: PATH_PAGE.glossary,
  },
]

export default menuConfig
