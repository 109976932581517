import { useRouter } from 'next/router'
import { styled, useTheme } from '@mui/material/styles'
import { Box, AppBar, Toolbar, Container } from '@mui/material'
import useOffSetTop from '../../hooks/useOffSetTop'
import useResponsive from '../../hooks/useResponsive'
import useLocales from '@/hooks/useLocales'
import useApp from '@/hooks/useApp'
import cssStyles from '../../utils/cssStyles'
import { HEADER } from '../../config'
import Logo from '../../components/Logo'
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'
import navConfig from './MenuConfig'
import SocialMediaLinksButton from '@/components/SocialMediaLinksButton'
import { ModuleEnum } from '@/appConfig'
import { formatPathname, isHomePage, isMintPage } from '@/utils/pathname'
import { PATH_PAGE } from '@/routes/paths'

// ----------------------------------------------------------------------

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('md')]: {
    height: HEADER.MAIN_DESKTOP_HEIGHT,
  },
}))

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8,
}))

// ----------------------------------------------------------------------

export default function MainHeader() {
  const isOffset = useOffSetTop(HEADER.MAIN_DESKTOP_HEIGHT)
  const app = useApp()
  const { translate } = useLocales()
  const theme = useTheme()

  const { pathname } = useRouter()

  const isDesktop = useResponsive('up', 'md')
  const formattedPathname = formatPathname(pathname)
  const isHome = isHomePage(formattedPathname)
  const isMint = isMintPage(formattedPathname)

  const isLocaleEnabled = app.modules.some((module) => module === ModuleEnum.Locale)

  const localedNavConfig = navConfig.map((nav) => ({
    ...nav,
    title: nav.isTranslateDisabled ? nav.title : translate(`menu.${nav.title}`),
  }))

  const renamedNavConfig = localedNavConfig.map((nav) => {
    let newTitle = nav.title
    switch (nav.path) {
      case '/':
        newTitle = app.renamePathDisplayTextHome ?? newTitle
        break
      case PATH_PAGE.blog:
        newTitle = app.renamePathDisplayTextBlog ?? newTitle
        break
      case PATH_PAGE.products:
        newTitle = app.renamePathDisplayTextProduct ?? newTitle
        break
      default:
        break
    }

    return { ...nav, title: newTitle }
  })

  const enabledModuleNavConfig = renamedNavConfig.filter((nav) =>
    nav.enabledModule ? app.modules.includes(nav.enabledModule) : true
  )

  const appNavConfig = [...enabledModuleNavConfig, ...app.navs]

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }} position={isMint ? 'static' : 'fixed'}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(!isMint &&
            isOffset && {
              ...cssStyles(theme).bgBlur(),
              height: { md: HEADER.MAIN_DESKTOP_HEIGHT - 16 },
            }),
        }}
      >
        <Container
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Logo
            isOffset={isOffset}
            isHome={isHome}
            isMint={isMint}
            sx={{ maxWidth: { xs: '150px', md: '200px' } }}
          />

          {/* <Label color="info" sx={{ ml: 1 }}>
            v3.0.0
          </Label> */}

          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && (
            <MenuDesktop
              isOffset={isOffset}
              isHome={isHome}
              isMint={isMint}
              navConfig={appNavConfig}
              isLocaleEnabled={isLocaleEnabled}
            />
          )}

          {isDesktop && (
            <SocialMediaLinksButton
              buttonTitle={app.renameCallToActionLinksDisplayText}
              linksCollection={app.socialMediaLinksCollection}
            />
          )}

          {!isDesktop && (
            <MenuMobile
              isOffset={isOffset}
              isHome={isHome}
              isMint={isMint}
              navConfig={appNavConfig}
              isLocaleEnabled={isLocaleEnabled}
            />
          )}
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  )
}
