import { ReactNode } from 'react'
import { Box, Link, Container, Typography, Stack, Fade, Fab, useScrollTrigger } from '@mui/material'
import Iconify from '@/components/Iconify'
import MainHeader from './MainHeader'
import useApp from '@/hooks/useApp'
import { useRouter } from 'next/router'
import { formatPathname, isMintPage } from '@/utils/pathname'
import StartsBackground from '@/components/StartsBackground'

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode
}

function ScrollTop(props: Props) {
  const { children } = props

  const trigger = useScrollTrigger({
    target: typeof window !== 'undefined' ? window : undefined,
    disableHysteresis: true,
    threshold: 100,
  })

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor'
    )

    if (anchor) {
      anchor.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      })
    }
  }

  return (
    <Fade in={trigger}>
      <Box onClick={handleClick} sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        {children}
      </Box>
    </Fade>
  )
}

export default function MainLayout({ children }: Props) {
  const { host, title } = useApp()
  const { pathname } = useRouter()
  const formattedPathname = formatPathname(pathname)
  const isMint = isMintPage(formattedPathname)

  return (
    <Stack sx={{ minHeight: 1 }}>
      <span id={'back-to-top-anchor'} />
      <MainHeader />
      {isMint && <StartsBackground />}

      {children}

      <Box sx={{ flexGrow: 1 }} />

      <Box
        sx={{
          py: 5,
          textAlign: 'center',
          position: 'relative',
          bgcolor: 'background.default',
        }}
      >
        <Container>
          <Typography variant="caption" component="p">
            © All rights reserved
            <br />
            <Link href={`https://${host}`}>{title}</Link>
          </Typography>
        </Container>
      </Box>
      <ScrollTop>
        <Fab size="small">
          <Iconify icon="ant-design:up-outlined" width={20} height={20} />
        </Fab>
      </ScrollTop>
    </Stack>
  )
}
