import styled, { keyframes } from 'styled-components'

const speedsInSeconds = {
  layer1: 30,
  layer2: 60,
  layer3: 90,
}

const StartsBackground = () => (
  <StartsContainer>
    <StartsFirst />
    <StartsSecond />
    <StartsThird />
  </StartsContainer>
)

export default StartsBackground

const animStar = keyframes`
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-2000px);
  }
`

const StartsContainer = styled.div`
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: scroll;
  position: fixed;
  z-index: -1;
`

const StartsFirst = styled.div`
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: 2px 1882px #fff, 962px 1308px #fff, 444px 437px #fff, 10px 1659px #fff,
    831px 1603px #fff, 1246px 708px #fff, 1754px 1890px #fff, 1675px 1656px #fff, 1191px 657px #fff,
    1735px 344px #fff, 340px 388px #fff, 771px 1017px #fff, 1804px 1609px #fff, 282px 1893px #fff,
    1655px 684px #fff, 614px 548px #fff, 1708px 1536px #fff, 1542px 792px #fff, 913px 658px #fff,
    366px 1792px #fff, 1252px 1120px #fff, 494px 1415px #fff, 231px 1974px #fff, 404px 241px #fff,
    1447px 1222px #fff, 1244px 1309px #fff, 299px 1350px #fff, 1385px 99px #fff, 314px 330px #fff,
    405px 319px #fff, 64px 1702px #fff, 132px 402px #fff, 751px 1106px #fff, 1963px 1225px #fff,
    101px 1690px #fff, 1070px 218px #fff, 723px 396px #fff, 1310px 885px #fff, 1612px 1069px #fff,
    229px 731px #fff, 364px 839px #fff, 1494px 1705px #fff, 1315px 378px #fff, 1247px 1415px #fff,
    1164px 811px #fff, 1955px 234px #fff, 856px 1531px #fff, 1821px 1478px #fff, 1852px 299px #fff,
    1242px 780px #fff, 625px 1618px #fff, 1642px 451px #fff, 75px 116px #fff, 1139px 318px #fff,
    1281px 1742px #fff, 1243px 192px #fff, 886px 430px #fff, 1832px 427px #fff, 1840px 737px #fff,
    118px 1083px #fff, 243px 849px #fff, 350px 1564px #fff, 383px 956px #fff, 1326px 846px #fff,
    1223px 855px #fff, 562px 964px #fff, 186px 1665px #fff, 668px 557px #fff, 1923px 333px #fff,
    1618px 329px #fff, 1711px 599px #fff, 616px 930px #fff, 969px 709px #fff, 1856px 618px #fff,
    1051px 1641px #fff, 970px 705px #fff, 1362px 1206px #fff, 662px 816px #fff, 611px 63px #fff,
    1208px 936px #fff, 246px 249px #fff, 1916px 1770px #fff, 326px 1342px #fff, 319px 86px #fff,
    1151px 928px #fff, 914px 1950px #fff, 706px 1229px #fff, 601px 601px #fff, 533px 265px #fff,
    554px 943px #fff, 274px 1320px #fff, 140px 1042px #fff, 1532px 1367px #fff, 1547px 629px #fff,
    870px 1814px #fff, 194px 1532px #fff, 38px 148px #fff, 229px 323px #fff, 672px 496px #fff,
    1854px 371px #fff, 586px 1049px #fff, 727px 899px #fff, 537px 953px #fff, 1314px 1672px #fff,
    888px 1336px #fff, 1950px 915px #fff, 711px 640px #fff, 1138px 1139px #fff, 1903px 130px #fff,
    1502px 1849px #fff, 1246px 438px #fff, 1104px 588px #fff, 1709px 1152px #fff, 1010px 750px #fff,
    698px 1229px #fff, 630px 1237px #fff, 715px 613px #fff, 12px 454px #fff, 54px 83px #fff,
    1693px 339px #fff, 1392px 622px #fff, 806px 1516px #fff, 707px 1821px #fff, 1209px 1751px #fff,
    463px 964px #fff, 1588px 1735px #fff, 923px 1502px #fff, 1324px 1417px #fff, 1126px 1837px #fff,
    1042px 1580px #fff, 325px 685px #fff, 1495px 923px #fff, 875px 482px #fff, 38px 776px #fff,
    139px 659px #fff, 385px 255px #fff, 863px 875px #fff, 1348px 166px #fff, 1795px 779px #fff,
    1737px 635px #fff, 1689px 365px #fff, 477px 277px #fff, 1451px 106px #fff, 1774px 199px #fff,
    1052px 476px #fff, 1951px 568px #fff, 192px 664px #fff, 1112px 1496px #fff, 205px 1194px #fff,
    14px 484px #fff, 187px 970px #fff, 1727px 704px #fff, 972px 771px #fff, 704px 639px #fff,
    223px 1589px #fff, 1222px 499px #fff, 1286px 1381px #fff, 46px 1485px #fff, 734px 72px #fff,
    868px 30px #fff, 910px 913px #fff, 368px 1030px #fff, 1514px 1189px #fff, 317px 1379px #fff,
    1987px 941px #fff, 1479px 1206px #fff, 719px 1658px #fff, 1848px 1493px #fff, 395px 1120px #fff,
    45px 1739px #fff, 1757px 509px #fff, 1466px 606px #fff, 370px 398px #fff, 45px 1586px #fff,
    882px 1072px #fff, 1972px 1420px #fff, 21px 732px #fff, 802px 1519px #fff, 159px 789px #fff,
    1134px 273px #fff, 1538px 317px #fff, 897px 1678px #fff, 1849px 1322px #fff, 666px 1164px #fff,
    1059px 799px #fff, 1193px 692px #fff, 1918px 1394px #fff, 1849px 444px #fff, 762px 1891px #fff,
    780px 1955px #fff, 466px 198px #fff, 690px 1160px #fff, 1359px 1066px #fff, 227px 973px #fff,
    1035px 540px #fff, 74px 1867px #fff, 1578px 308px #fff, 1232px 325px #fff, 1560px 975px #fff,
    1459px 59px #fff, 1895px 804px #fff, 665px 489px #fff, 268px 249px #fff, 1138px 1303px #fff,
    451px 1546px #fff, 1290px 1288px #fff, 714px 402px #fff, 1627px 1349px #fff, 1293px 128px #fff,
    865px 264px #fff, 467px 204px #fff, 1766px 563px #fff, 512px 79px #fff, 1593px 932px #fff,
    939px 735px #fff, 111px 1135px #fff, 1059px 921px #fff, 1891px 579px #fff, 705px 792px #fff,
    1933px 1301px #fff, 706px 118px #fff, 1148px 1982px #fff, 940px 471px #fff, 1890px 1863px #fff,
    878px 1330px #fff, 548px 1044px #fff, 704px 1709px #fff, 379px 87px #fff, 213px 1368px #fff,
    1892px 860px #fff, 1948px 634px #fff, 936px 1961px #fff, 1785px 702px #fff, 841px 928px #fff,
    437px 900px #fff, 790px 880px #fff, 925px 417px #fff, 459px 1712px #fff, 304px 665px #fff,
    1747px 1732px #fff, 1199px 1102px #fff, 1556px 940px #fff, 544px 260px #fff, 737px 1000px #fff,
    1003px 1868px #fff, 914px 1964px #fff, 24px 1723px #fff, 807px 1240px #fff, 1695px 1390px #fff,
    450px 589px #fff, 1021px 1559px #fff, 599px 953px #fff, 1651px 577px #fff, 1847px 329px #fff,
    223px 546px #fff, 287px 1400px #fff, 1586px 1745px #fff, 1063px 1885px #fff, 1086px 346px #fff,
    1715px 673px #fff, 1495px 1469px #fff, 1939px 804px #fff, 1964px 1673px #fff, 819px 1359px #fff,
    106px 835px #fff, 1293px 1775px #fff, 864px 1194px #fff, 694px 785px #fff, 1143px 95px #fff,
    735px 751px #fff, 1591px 1470px #fff, 1455px 1546px #fff, 1031px 1404px #fff, 1654px 36px #fff,
    899px 262px #fff, 727px 283px #fff, 1919px 902px #fff, 1674px 1650px #fff, 9px 1592px #fff,
    836px 1550px #fff, 1195px 1354px #fff, 1564px 702px #fff, 864px 1784px #fff, 1087px 535px #fff,
    952px 1129px #fff, 1476px 538px #fff, 1192px 57px #fff, 755px 1432px #fff, 645px 957px #fff,
    480px 186px #fff, 1856px 1112px #fff, 1400px 290px #fff, 1089px 446px #fff, 1211px 747px #fff,
    849px 716px #fff, 731px 724px #fff, 77px 583px #fff, 969px 230px #fff, 1155px 1814px #fff,
    1119px 1116px #fff, 1557px 347px #fff, 990px 558px #fff, 1233px 1462px #fff, 1415px 281px #fff,
    718px 638px #fff, 556px 1049px #fff, 1946px 1290px #fff, 1575px 1492px #fff, 55px 1780px #fff,
    677px 896px #fff, 605px 1723px #fff, 1500px 1676px #fff, 1649px 683px #fff, 1374px 1478px #fff,
    631px 754px #fff, 831px 1284px #fff, 302px 1046px #fff, 63px 408px #fff, 953px 930px #fff,
    944px 497px #fff, 743px 661px #fff, 103px 1794px #fff, 503px 1118px #fff, 863px 1358px #fff,
    1104px 461px #fff, 1722px 1300px #fff, 636px 743px #fff, 488px 925px #fff, 1634px 559px #fff,
    683px 1987px #fff, 1122px 540px #fff, 352px 1198px #fff, 1341px 1503px #fff, 1407px 1176px #fff,
    1498px 1229px #fff, 1694px 1385px #fff, 408px 431px #fff, 506px 289px #fff, 932px 1888px #fff,
    1632px 508px #fff, 1728px 451px #fff, 1397px 1137px #fff, 1449px 357px #fff, 1306px 1851px #fff,
    232px 431px #fff, 1859px 492px #fff, 598px 1120px #fff, 1523px 782px #fff, 324px 431px #fff,
    626px 1020px #fff, 329px 1871px #fff, 1297px 283px #fff, 1914px 1273px #fff, 1135px 281px #fff,
    362px 226px #fff, 1552px 349px #fff, 1490px 521px #fff, 1053px 1981px #fff, 1616px 63px #fff,
    1202px 1752px #fff, 1033px 1517px #fff, 1845px 1772px #fff, 301px 839px #fff, 192px 898px #fff,
    994px 225px #fff, 316px 1972px #fff, 1205px 195px #fff, 1328px 387px #fff, 882px 1186px #fff,
    1492px 1208px #fff, 1052px 1087px #fff, 663px 261px #fff, 399px 1248px #fff, 1107px 1314px #fff,
    281px 731px #fff, 1509px 184px #fff, 692px 1101px #fff, 1083px 42px #fff, 1285px 1657px #fff,
    1159px 1931px #fff, 729px 1486px #fff, 1127px 1091px #fff, 1402px 1807px #fff, 1383px 527px #fff,
    971px 817px #fff, 1428px 1744px #fff, 515px 1493px #fff, 488px 1761px #fff, 1313px 1376px #fff,
    43px 1384px #fff, 1796px 1226px #fff, 58px 1816px #fff, 1838px 1219px #fff, 770px 792px #fff,
    1447px 1056px #fff, 1569px 1810px #fff, 905px 666px #fff, 286px 1554px #fff, 699px 580px #fff,
    1509px 1239px #fff, 1267px 738px #fff, 804px 198px #fff, 733px 1480px #fff, 1527px 511px #fff,
    1750px 1087px #fff, 464px 753px #fff, 1683px 143px #fff, 1606px 1014px #fff, 928px 693px #fff,
    1064px 1165px #fff, 640px 33px #fff, 1369px 670px #fff, 979px 161px #fff, 1816px 1117px #fff,
    83px 403px #fff, 33px 133px #fff, 255px 1155px #fff, 1135px 600px #fff, 252px 271px #fff,
    1499px 491px #fff, 1040px 1316px #fff, 517px 828px #fff, 1979px 1158px #fff, 437px 101px #fff,
    287px 662px #fff, 1547px 662px #fff, 147px 1811px #fff, 1578px 261px #fff, 470px 789px #fff,
    861px 64px #fff, 784px 862px #fff, 1459px 1899px #fff, 235px 1728px #fff, 1078px 207px #fff,
    729px 1929px #fff, 1308px 681px #fff, 1399px 1898px #fff, 1031px 1093px #fff, 197px 1226px #fff,
    277px 936px #fff, 1531px 1882px #fff, 1698px 1434px #fff, 1193px 1788px #fff, 1509px 388px #fff,
    1720px 1859px #fff, 897px 1246px #fff, 1129px 599px #fff, 1064px 183px #fff, 73px 1061px #fff,
    1614px 794px #fff, 610px 287px #fff, 1942px 1106px #fff, 800px 1694px #fff, 168px 1221px #fff,
    1786px 157px #fff, 866px 299px #fff, 32px 124px #fff, 151px 1527px #fff, 558px 1575px #fff,
    641px 1687px #fff, 22px 1116px #fff, 1736px 1326px #fff, 1746px 1429px #fff, 608px 1922px #fff,
    1747px 88px #fff, 37px 91px #fff, 131px 271px #fff, 410px 655px #fff, 1332px 68px #fff,
    1244px 167px #fff, 1089px 94px #fff, 539px 1582px #fff, 1445px 757px #fff, 301px 110px #fff,
    1729px 529px #fff, 1153px 1991px #fff, 1524px 1813px #fff, 219px 1084px #fff, 1746px 1156px #fff,
    673px 1993px #fff, 83px 89px #fff, 1841px 534px #fff, 831px 61px #fff, 80px 252px #fff,
    1801px 1452px #fff, 1633px 1523px #fff, 419px 313px #fff, 13px 1115px #fff, 319px 1075px #fff,
    1032px 1981px #fff, 1302px 1021px #fff, 510px 1955px #fff, 110px 1517px #fff, 1002px 1927px #fff,
    1844px 174px #fff, 576px 1477px #fff, 83px 1154px #fff, 1079px 391px #fff, 842px 93px #fff,
    1588px 746px #fff, 1491px 714px #fff, 1310px 471px #fff, 1698px 1188px #fff, 1180px 47px #fff,
    1608px 1516px #fff, 276px 164px #fff, 816px 102px #fff, 995px 1562px #fff, 148px 1901px #fff,
    494px 281px #fff, 1357px 1165px #fff, 14px 115px #fff, 1936px 252px #fff, 398px 6px #fff,
    264px 1351px #fff, 897px 1327px #fff, 1348px 466px #fff, 1796px 1189px #fff, 1079px 1233px #fff,
    1052px 1932px #fff, 1043px 1055px #fff, 738px 510px #fff, 1291px 861px #fff, 296px 256px #fff,
    1775px 420px #fff, 312px 619px #fff, 1794px 1356px #fff, 159px 412px #fff, 108px 994px #fff,
    780px 519px #fff, 1414px 508px #fff, 245px 588px #fff, 1702px 559px #fff, 936px 568px #fff,
    672px 38px #fff, 857px 1411px #fff, 507px 129px #fff, 1826px 1983px #fff, 1189px 525px #fff,
    1739px 1601px #fff, 311px 1100px #fff, 214px 829px #fff, 1442px 1334px #fff, 482px 802px #fff,
    192px 861px #fff, 1220px 1933px #fff, 985px 1793px #fff, 756px 1624px #fff, 301px 1134px #fff,
    274px 202px #fff, 1187px 1534px #fff, 1929px 1175px #fff, 974px 163px #fff, 335px 1817px #fff,
    1039px 482px #fff, 5px 1895px #fff, 1624px 1022px #fff, 571px 434px #fff, 254px 1802px #fff,
    275px 1762px #fff, 535px 557px #fff, 1966px 947px #fff, 183px 226px #fff, 1745px 1577px #fff,
    1113px 1524px #fff, 142px 23px #fff, 1777px 132px #fff, 471px 145px #fff, 609px 710px #fff,
    1824px 1838px #fff, 939px 1711px #fff, 1894px 1595px #fff, 267px 2000px #fff, 1319px 1096px #fff,
    1194px 616px #fff, 138px 1216px #fff, 1533px 1228px #fff, 1224px 1480px #fff, 1246px 690px #fff,
    1868px 520px #fff, 134px 587px #fff, 795px 420px #fff, 1014px 654px #fff, 1832px 308px #fff,
    1325px 1459px #fff, 84px 149px #fff, 1100px 42px #fff, 1121px 274px #fff, 1726px 322px #fff,
    65px 1199px #fff, 1013px 1920px #fff, 814px 899px #fff, 970px 706px #fff, 840px 858px #fff,
    855px 1760px #fff, 1027px 1052px #fff, 1896px 1007px #fff, 253px 1960px #fff, 1856px 396px #fff,
    1821px 1921px #fff, 1014px 456px #fff, 189px 1499px #fff, 555px 1936px #fff, 1569px 1593px #fff,
    695px 614px #fff, 535px 1914px #fff, 680px 1198px #fff, 723px 1584px #fff, 1443px 1500px #fff,
    512px 326px #fff, 1345px 383px #fff, 373px 470px #fff, 1298px 1113px #fff, 3px 1285px #fff,
    387px 803px #fff, 840px 1849px #fff, 378px 1695px #fff, 1267px 109px #fff, 1030px 392px #fff,
    191px 1746px #fff, 867px 504px #fff, 346px 653px #fff, 716px 1118px #fff, 1095px 1238px #fff,
    357px 108px #fff, 159px 1589px #fff, 1085px 1557px #fff, 1126px 1782px #fff, 1652px 1443px #fff,
    84px 661px #fff, 1365px 1px #fff, 1867px 1919px #fff, 1494px 1407px #fff, 42px 506px #fff,
    116px 779px #fff, 1527px 1994px #fff, 66px 1145px #fff, 860px 510px #fff, 1627px 1565px #fff,
    1061px 939px #fff, 195px 1932px #fff, 1760px 406px #fff, 623px 781px #fff, 221px 454px #fff,
    418px 908px #fff, 1978px 1423px #fff, 244px 522px #fff, 319px 1638px #fff, 495px 1046px #fff,
    803px 426px #fff, 851px 368px #fff, 918px 1193px #fff, 1844px 358px #fff, 1286px 679px #fff,
    853px 801px #fff, 663px 508px #fff, 490px 1800px #fff, 939px 97px #fff, 385px 922px #fff,
    391px 1810px #fff, 1406px 915px #fff, 27px 1694px #fff, 1179px 1399px #fff, 1194px 844px #fff,
    1989px 574px #fff, 1497px 1269px #fff, 685px 149px #fff, 1845px 1342px #fff, 1669px 277px #fff,
    1930px 616px #fff, 1404px 1858px #fff, 19px 711px #fff, 549px 1596px #fff, 1361px 1843px #fff,
    1001px 1285px #fff, 1823px 1784px #fff, 1528px 558px #fff, 153px 1102px #fff, 444px 1857px #fff,
    1151px 1822px #fff, 888px 381px #fff, 659px 128px #fff, 523px 1566px #fff, 372px 100px #fff,
    945px 534px #fff, 116px 1559px #fff, 1707px 423px #fff, 45px 178px #fff, 267px 1312px #fff,
    602px 928px #fff, 77px 245px #fff, 833px 1241px #fff, 1178px 697px #fff, 1599px 407px #fff,
    616px 1263px #fff, 92px 1039px #fff, 19px 1820px #fff, 1930px 1161px #fff, 821px 864px #fff,
    911px 714px #fff;
  animation: ${animStar} ${speedsInSeconds.layer1}s linear infinite;
`

const StartsSecond = styled.div`
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: 317px 173px #fff, 185px 1866px #fff, 210px 579px #fff, 1772px 1078px #fff,
    1886px 1865px #fff, 673px 744px #fff, 830px 1321px #fff, 1919px 575px #fff, 461px 1600px #fff,
    1924px 1592px #fff, 255px 817px #fff, 149px 345px #fff, 1424px 1620px #fff, 1545px 1205px #fff,
    140px 1614px #fff, 1185px 354px #fff, 1700px 1773px #fff, 722px 1413px #fff, 410px 1678px #fff,
    671px 781px #fff, 1092px 1455px #fff, 1698px 1515px #fff, 1001px 614px #fff, 1496px 55px #fff,
    320px 1496px #fff, 494px 1421px #fff, 63px 310px #fff, 644px 803px #fff, 1394px 930px #fff,
    545px 771px #fff, 1949px 335px #fff, 664px 1719px #fff, 164px 845px #fff, 1012px 1825px #fff,
    818px 1923px #fff, 185px 1558px #fff, 1913px 1577px #fff, 489px 381px #fff, 1889px 80px #fff,
    1320px 249px #fff, 1061px 946px #fff, 133px 799px #fff, 374px 761px #fff, 1167px 1018px #fff,
    939px 2px #fff, 1626px 42px #fff, 1336px 1445px #fff, 589px 417px #fff, 1828px 1548px #fff,
    1576px 997px #fff, 745px 1005px #fff, 455px 1351px #fff, 1169px 370px #fff, 493px 227px #fff,
    1902px 124px #fff, 161px 260px #fff, 1244px 646px #fff, 1258px 720px #fff, 653px 88px #fff,
    1104px 1795px #fff, 1001px 1990px #fff, 795px 862px #fff, 1055px 443px #fff, 458px 1279px #fff,
    358px 162px #fff, 1636px 677px #fff, 1555px 1629px #fff, 199px 290px #fff, 58px 1584px #fff,
    1302px 275px #fff, 1138px 49px #fff, 1945px 1408px #fff, 1828px 1658px #fff, 1563px 23px #fff,
    624px 980px #fff, 1135px 1006px #fff, 11px 306px #fff, 1798px 732px #fff, 65px 1473px #fff,
    1879px 1538px #fff, 841px 1517px #fff, 78px 407px #fff, 676px 854px #fff, 582px 1635px #fff,
    1572px 741px #fff, 1658px 935px #fff, 645px 1864px #fff, 1229px 599px #fff, 325px 1090px #fff,
    116px 1210px #fff, 554px 1227px #fff, 1088px 920px #fff, 1226px 867px #fff, 1327px 1674px #fff,
    1891px 1508px #fff, 692px 1016px #fff, 1395px 819px #fff, 617px 1637px #fff, 278px 425px #fff,
    228px 1551px #fff, 1775px 1707px #fff, 1509px 809px #fff, 190px 1950px #fff, 941px 1213px #fff,
    1886px 901px #fff, 1005px 520px #fff, 1155px 409px #fff, 1274px 1380px #fff, 912px 1026px #fff,
    245px 1053px #fff, 1281px 665px #fff, 695px 23px #fff, 1540px 789px #fff, 334px 541px #fff,
    685px 1151px #fff, 1516px 1776px #fff, 1267px 461px #fff, 1657px 477px #fff, 282px 172px #fff,
    1908px 821px #fff, 787px 1839px #fff, 901px 707px #fff, 1425px 1078px #fff, 1700px 732px #fff,
    1378px 1295px #fff, 639px 280px #fff, 519px 39px #fff, 636px 500px #fff, 1809px 561px #fff,
    1281px 1464px #fff, 1816px 1939px #fff, 1770px 930px #fff, 247px 1834px #fff, 223px 791px #fff,
    1017px 11px #fff, 1238px 925px #fff, 1698px 246px #fff, 1057px 1960px #fff, 1837px 1605px #fff,
    1355px 1428px #fff, 1553px 1774px #fff, 1860px 1747px #fff, 1803px 340px #fff, 1127px 438px #fff,
    1789px 249px #fff, 750px 827px #fff, 63px 308px #fff, 841px 101px #fff, 196px 238px #fff,
    10px 1975px #fff, 1873px 1250px #fff, 982px 1769px #fff, 1378px 1688px #fff, 486px 1076px #fff,
    621px 1468px #fff, 1226px 1661px #fff, 1614px 1627px #fff, 1663px 385px #fff, 1798px 1447px #fff,
    1406px 264px #fff, 57px 1962px #fff, 1984px 1742px #fff, 1248px 396px #fff, 653px 1692px #fff,
    611px 1508px #fff, 1975px 1959px #fff, 1953px 1854px #fff, 1308px 1502px #fff, 680px 1920px #fff,
    1213px 212px #fff, 204px 1535px #fff, 406px 456px #fff, 785px 108px #fff, 1462px 1258px #fff,
    1041px 1688px #fff, 1014px 690px #fff, 1536px 139px #fff, 1458px 1735px #fff, 1823px 420px #fff,
    1351px 1283px #fff, 1555px 1290px #fff, 1952px 699px #fff, 849px 133px #fff, 776px 1133px #fff,
    1165px 386px #fff, 1998px 1694px #fff, 186px 1756px #fff, 335px 1280px #fff, 948px 1024px #fff,
    177px 401px #fff, 1747px 4px #fff, 785px 540px #fff, 477px 1784px #fff, 61px 640px #fff,
    1878px 1488px #fff, 1733px 1462px #fff, 111px 1339px #fff, 1529px 548px #fff, 965px 587px #fff,
    1092px 446px #fff;
  animation: ${animStar} ${speedsInSeconds.layer2}s linear infinite;
`

const StartsThird = styled.div`
  #stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: 1509px 751px #fff, 1460px 334px #fff, 1014px 1612px #fff, 532px 255px #fff,
      36px 1498px #fff, 228px 1498px #fff, 301px 190px #fff, 197px 14px #fff, 1952px 1231px #fff,
      831px 193px #fff, 1262px 209px #fff, 908px 930px #fff, 1685px 1828px #fff, 1333px 1170px #fff,
      1966px 990px #fff, 1666px 1706px #fff, 900px 336px #fff, 1281px 414px #fff, 861px 614px #fff,
      1906px 495px #fff, 1068px 212px #fff, 1340px 1374px #fff, 1694px 1856px #fff, 651px 251px #fff,
      517px 835px #fff, 590px 947px #fff, 540px 1742px #fff, 658px 1829px #fff, 282px 390px #fff,
      1378px 1890px #fff, 61px 671px #fff, 1437px 270px #fff, 1999px 1579px #fff, 236px 961px #fff,
      794px 1882px #fff, 1668px 190px #fff, 1623px 1581px #fff, 754px 344px #fff, 438px 760px #fff,
      1468px 375px #fff, 479px 424px #fff, 888px 10px #fff, 741px 116px #fff, 1716px 1866px #fff,
      599px 632px #fff, 1768px 1064px #fff, 578px 1921px #fff, 167px 824px #fff, 1636px 1798px #fff,
      310px 1896px #fff, 1993px 1298px #fff, 460px 950px #fff, 236px 1328px #fff, 297px 327px #fff,
      190px 946px #fff, 1114px 819px #fff, 1966px 1260px #fff, 729px 1983px #fff, 108px 1453px #fff,
      732px 1869px #fff, 1292px 1435px #fff, 582px 1002px #fff, 1982px 163px #fff,
      1546px 1926px #fff, 493px 120px #fff, 874px 581px #fff, 464px 255px #fff, 748px 992px #fff,
      1171px 1723px #fff, 1857px 1722px #fff, 1069px 403px #fff, 1219px 1940px #fff,
      81px 1578px #fff, 1653px 277px #fff, 1589px 812px #fff, 370px 1294px #fff, 936px 1007px #fff,
      326px 547px #fff, 1183px 599px #fff, 870px 675px #fff, 1466px 704px #fff, 677px 519px #fff,
      1366px 1172px #fff, 1003px 1053px #fff, 1092px 1848px #fff, 629px 889px #fff,
      1274px 232px #fff, 934px 1723px #fff, 679px 653px #fff, 1308px 1706px #fff, 75px 1369px #fff,
      467px 1706px #fff, 1297px 1948px #fff, 205px 962px #fff, 646px 1174px #fff, 884px 217px #fff,
      642px 1826px #fff, 1300px 571px #fff, 1265px 1964px #fff, 791px 1795px #fff;
    animation: ${animStar} ${speedsInSeconds.layer3}s linear infinite;
  }
`
